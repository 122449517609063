import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cn } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CheckboxElement = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
            'peer size-5 shrink-0 rounded border border-gray-400 hover:border-gray-600 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-violet-800 data-[state=checked]:hover:bg-violet-900',
            className
        )}
        {...props}
    >
        <CheckboxPrimitive.Indicator className="flex items-center justify-center text-white">
            <FontAwesomeIcon icon="check" className="size-3" />
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
));
CheckboxElement.displayName = CheckboxPrimitive.Root.displayName;

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxElement> {
    label: string;
}

export function CheckBox(props: CheckboxProps) {
    const { label, ...checkboxProps } = props;
    const uuid = React.useRef<string>(Math.random().toString(36).substring(7));

    return (
        <div className="flex items-center gap-3 text-gray-700 hover:text-gray-950">
            <CheckboxElement id={uuid.current} {...checkboxProps} />
            <label htmlFor={uuid.current} className={cn('tg-body-medium', props.checked && 'text-gray-950')}>
                {label}
            </label>
        </div>
    );
}
