import { gql } from '@apollo/client';

const UPDATE_TRAVEL_AFTER_SEND = gql`
    mutation UpdateTravelCostAfterSend($costInvoiceId: Int!, $travelId: Int!, $travel: InputTravel!) {
        updateTravel(costInvoiceId: $costInvoiceId, travelId: $travelId, travel: $travel) {
            allowancesTotal
            id
            dailyAllowancesFull {
                quantity
                salaryType
            }
            dailyAllowancesHalf {
                quantity
            }
            endTime
            id
            receiptCosts {
                id
                amountsWithVats {
                    amount
                    id
                    vatPercent
                }
                createTime
                description
                purchaseDate
                receiptFiles {
                    contentType
                    createTime
                    id
                    name
                    previewUrl
                    url
                }
                type
            }
            reimbursedKMs {
                quantity
            }
            reimbursedMeals {
                quantity
            }
            reimbursedTrailerKMs {
                quantity
            }
            route
            startTime
        }
    }
`;

export default UPDATE_TRAVEL_AFTER_SEND;
