import { useMemo } from 'react';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from 'components/ui/chart';
import { Area, CartesianGrid, ComposedChart, Line, XAxis, YAxis } from 'recharts';
import { capitalize } from 'utils/str';
import { SalesPageStatisticData } from 'containers/statistics/statisticsPageTypes';

interface ISalesStatsBillingChart {
    mainGraph: SalesPageStatisticData;
    data: SalesPageStatisticData[];
}

const SalesStatsBillingChart = ({ mainGraph, data }: ISalesStatsBillingChart) => {
    // this is the tailwindcss md breakpoint
    const isMobile = window.innerWidth < 768;
    const allCharts = useMemo(() => [mainGraph, ...data], [mainGraph, data]);

    const chartConfig = useMemo(() => {
        const conf: ChartConfig = {};

        allCharts.forEach(({ name, color }) => (conf[name] = { label: name, color }));

        return conf;
    }, [allCharts]);

    const longestData = useMemo(
        () =>
            allCharts.length
                ? allCharts.reduce((a, b) => (a.items.length > b.items.length ? a : b)).items
                : [],
        [allCharts]
    );

    const chartData = useMemo(() => {
        // Find data.items with longest length
        if (!allCharts.length) return [];
        return longestData.map((item) => {
            const chartItem: { label: string } & Record<string, number | string> = { label: item.label };
            allCharts.forEach(
                (value) =>
                    (chartItem[value.name] = value.items.find((i) => i.label === item.label)?.value ?? 0)
            );

            return chartItem;
        });
    }, [allCharts, longestData]);

    return (
        <ChartContainer
            config={chartConfig}
            className="max-h-[300px] w-full [&_svg]:overflow-visible !font-jakarta"
        >
            <ComposedChart data={chartData} accessibilityLayer>
                <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#AB9AEB" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#AB9AEB" stopOpacity={0.1} />
                    </linearGradient>
                </defs>
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey="label"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={10}
                    interval="preserveStartEnd"
                />
                <YAxis
                    tickLine={false}
                    axisLine={false}
                    tickMargin={10}
                    tickFormatter={(value) => `${value.toLocaleString('fi')}€`}
                    mirror={isMobile}
                    dy={isMobile ? -10 : 0}
                />
                <ChartTooltip
                    animationDuration={300}
                    content={
                        <ChartTooltipContent
                            labelFormatter={(label) => capitalize(label)}
                            formatValue={(value) =>
                                new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(
                                    isNaN(value) ? 0 : (value as number)
                                )
                            }
                        />
                    }
                />
                <Area dataKey={mainGraph.name} color="#AB9AEB" fill="url(#gradient)" stroke="#AB9AEB" />
                {data.map(({ name, color }) => (
                    <Line key={name} dataKey={name} stroke={color} dot={false} strokeWidth={2} />
                ))}
            </ComposedChart>
        </ChartContainer>
    );
};

export default SalesStatsBillingChart;
