import { gql } from '@apollo/client';

const DELETE_INVOICE_ITEM = gql`
    mutation DeleteInvoiceItem($id: Int!, $invoiceId: Int!) {
        deleteInvoiceItem(item: { id: $id, invoiceId: $invoiceId }) {
            id
            invoiceItems {
                description
                endDate
                id
                itemType
                price
                quantity
                quantityUnit
                startDate
                totalPrice
                totalPriceWithVat
                vat
            }
            total
            totalVats {
                percentage
                total
            }
            totalWithVat
        }
    }
`;

export default DELETE_INVOICE_ITEM;
