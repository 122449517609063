import { gql } from '@apollo/client';

const GET_COWORKERS = gql`
    query GetCoworkers {
        coworkers {
            contacts {
                id
                name
            }
            pendingContacts {
                id
                name
            }
            requestedContacts {
                id
                name
            }
        }
    }
`;

export default GET_COWORKERS;
