import { gql } from '@apollo/client';

const GET_SALES_PAGES_BY_PATH = gql`
    query GetSalesPagesByPath($publicPath: String) {
        sales_pages(order_by: { id: desc }, where: { publicPath: { _ilike: $publicPath } }) {
            id
            serviceName
            servicePrice
            serviceDescription
            numberOfPayments
            creationDate
            publicPath
            serviceVat
            occupationId
        }
    }
`;

export default GET_SALES_PAGES_BY_PATH;
