import { gql } from '@apollo/client';

const ADD_PROVISIONS = gql`
    mutation AddProvisions($id: Int!, $provisions: [ProvisionInput!]) {
        updateInvoice(invoice: { id: $id, provisions: $provisions }) {
            id
            provisions {
                name
                occupationId
                percentage
                personId
                fixedAmount
                travelExpenses
            }
        }
    }
`;

export default ADD_PROVISIONS;
