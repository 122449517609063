import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faCalendar,
    faCalendarTimes,
    faCheck,
    faChevronDown,
    faChevronUp,
    faClock,
    faCoins,
    faEnvelopeOpenText,
    faEuroSign,
    faExclamationTriangle,
    faPen,
    faPencilAlt,
    faSearch,
    faTimes,
} from '@fortawesome/pro-light-svg-icons';
import {
    faArrowLeft,
    faArrowRight,
    faArrowToBottom,
    faArrowToTop,
    faArrowUpRightFromSquare,
    faCalendarAlt,
    faCalendarDay,
    faCheck as farCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faCircle as farCircle,
    faCircleRight,
    faClipboardList,
    faCommentAltLines,
    faCommentDots,
    faCopy,
    faEllipsisV,
    faEnvelope,
    faEnvelopeOpen,
    faExclamationCircle,
    faExclamationTriangle as farExclamationTriangle,
    faEye,
    faEyeSlash,
    faFileLines,
    faFrown,
    faMinus,
    faPaperclip,
    faPen as farPen,
    faPennant,
    faPlus,
    faRotate,
    faSpinner,
    faTrashAlt,
    faUserCowboy,
    faUserCrown,
    faUserFriends,
    faArrowsCross as farArrowCross,
    faCoins as farCoins,
    faChartMixed as farChartMixed,
    faXmark as farXmark,
    faArrowDown as farArrowDown,
    faArrowUp as farArrowUp,
    faCalendar as farCalendar,
    faClock as farClock,
    faFileInvoice as farFileInvoice,
    faInfoCircle as farInfoCircle,
    faChevronDown as farChevronDown,
    faChevronUp as farChevronUp,
    faSearch as farSearch,
    faShuffle as farShuffle,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faExclamationTriangle as fasExclamationTriangle,
    faArrowLeft as fasArrowLeft,
    faShare as fasShare,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faChevronUp as fasChevronUp,
    faChevronDown as fasChevronDown,
    faLink as fasLink,
    faSearch as fasSearch,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faArrowAltRight,
    faCheck as fasCheck,
    faCircle as fasCircle,
    faCoins as fasCoins,
    faCommentDots as fasCommentDots,
    faEuroSign as fasEuroSign,
    faExclamation,
    faHomeLgAlt,
    faPhone,
    faPlay,
    faQuestion,
    faReceipt,
    faRedo,
    faInfoCircle,
    faChartMixed as fasChartMixed,
    faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';

/*
    https://fontawesome.com/icons

    Font awesome icon usage, e.g.:
        import { Icon } from '../../components/Icon';
        
        <Icon icon={['fal', 'pen']} />
        <Icon icon={['far', 'exclamation-circle']} />

    light => fal
    regular => far
    solid => fas
*/

export const initFAIcons = () => {
    library.add(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        faArrowAltRight,
        faArrowRight,
        faArrowLeft,
        fasArrowLeft,
        faArrowToBottom,
        faArrowToTop,
        faBars,
        faCalendar,
        faCalendarAlt,
        faCalendarDay,
        faCalendarTimes,
        faCheck,
        faCheckCircle,
        farCircle,
        fasCircle,
        faCircleRight,
        faClipboardList,
        farCheck,
        fasCheck,
        faClock,
        faChevronDown,
        fasShare,
        farArrowUp,
        farCalendar,
        farClock,
        farFileInvoice,
        farInfoCircle,
        farChevronDown,
        farChevronUp,
        farSearch,
        faChevronLeft,
        faChevronRight,
        faChevronUp,
        fasChevronUp,
        fasChevronDown,
        faCoins,
        fasCoins,
        farArrowCross,
        farCoins,
        farChartMixed,
        farXmark,
        farArrowDown,
        faCommentAltLines,
        faCommentDots,
        fasCommentDots,
        fasChevronLeft,
        fasChevronRight,
        faCopy,
        faEllipsisV,
        faEnvelope,
        faEnvelopeOpen,
        faEnvelopeOpenText,
        faEuroSign,
        fasEuroSign,
        faExclamation,
        faExclamationCircle,
        faExclamationTriangle,
        farExclamationTriangle,
        fasExclamationTriangle,
        faArrowUpRightFromSquare,
        faEye,
        faEyeSlash,
        faFileLines,
        faFrown,
        faHomeLgAlt,
        faMinus,
        faPaperclip,
        faPen,
        faPencilAlt,
        farPen,
        faPennant,
        faPhone,
        faPlay,
        faPlus,
        faRotate,
        faQuestion,
        faReceipt,
        faRedo,
        faSearch,
        faSpinner,
        fasSearch,
        faTimes,
        faTrashAlt,
        faUserCowboy,
        faUserCrown,
        faUserFriends,
        fasLink,
        fasChartMixed,
        faInfoCircle,
        faSpinnerThird,
        farShuffle,
    );
};
