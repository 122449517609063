import { adaptV4Theme, createTheme } from '@mui/material/styles';
import { createGlobalStyle } from 'styled-components';
import {
    COLOR_BLUM,
    COLOR_GRAYS_ANATOMY,
    COLOR_IMPORTANT,
    COLOR_LILA,
    COLOR_STATUS_WAITING,
    FOCUS_COLOR,
} from './variables';

export default createGlobalStyle`

@font-face {
    font-family: 'euclid';
    src: url('/e/f/euclid/euclidcirculara-semibold-webfont.woff2') format('woff2'),
         url('/e/f/euclid/euclidcirculara-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'euclid';
    src: url('/e/f/euclid/euclidcirculara-regular-webfont.woff2') format('woff2'),
         url('/e/f/euclid/euclidcirculara-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'euclid';
    src: url('/e/f/euclid/euclidcirculara-light-webfont.woff2') format('woff2'),
         url('/e/f/euclid/euclidcirculara-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'euclid';
    src: url('/e/f/euclid/euclidcirculara-bold-webfont.woff2') format('woff2'),
         url('/e/f/euclid/euclidcirculara-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

* {
    font-family: 'euclid', sans-serif;
}
*:focus {
    outline: none;
}
*:not(button):not(input):not(textarea):not(div):not(label):focus {
    outline: ${FOCUS_COLOR} auto 1px;
}
html, body, #root {
    cursor: default;
    height: 100%;
}
html body, select, input[type=date], input[type=text], textarea {
    font-size: 15px;
    /* letter-spacing: 0.15px; */
    line-height: 24px;
}
input, textarea {
    caret-color: ${FOCUS_COLOR};
}
body {
    -webkit-font-smoothing: antialiased;
}
a {
    color: ${COLOR_BLUM};
    text-decoration: underline;
}
a.unstyled {
    color: inherit;
    text-decoration: none;
}
ul {
    font-size: 15px;
    list-style: none;
}
button, a {
    background: none;
    cursor: pointer;
}
.invisible {
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px; width: 1px;
  overflow: hidden;
  position: absolute !important;
}
.hide-scroll {
     /* Hide scrollbar but allow scroll on IE & edge */
     -ms-overflow-style: none;
    /* Hide scrollbar on ff */
    scrollbar-width: none;
    &::-webkit-scrollbar {
        /* Hide scrollbar but allow scroll on  chrome, safari, opera */
        display: none;
    }
}

.show-scroll {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${COLOR_GRAYS_ANATOMY};
    border-radius: 4px;
    margin-block: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 4px;
  }

  /* Buttons */
  &::-webkit-scrollbar-button:single-button {
    background-color: white;
    display: block;
    border-style: solid;
    height: 13px;
    width: 16px;
    background-clip: padding-box;
  }
  /* Up */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent ${COLOR_BLUM} transparent;
  }
  &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent ${COLOR_LILA} transparent;
  }
  /* Down */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 5px 5px 0 5px;
    border-color: ${COLOR_BLUM} transparent transparent transparent;
  }
  &::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: ${COLOR_LILA} transparent transparent transparent;
  }

  & ul {
    & li {
      .item-content {
        margin: 0 3px 0 0;
      }
    }
  }
}

&::selection {
    background-color: rgba(223, 25, 149, 0.4);
}

`;

export const theme = createTheme(
    adaptV4Theme({
        breakpoints: {
            values: {
                xs: 0,
                // tslint:disable-next-line: object-literal-sort-keys
                sm: 600,
                md: 1110, // 1/2-palstaisuuden raja
                lg: 1280,
                xl: 1920,
            },
        },
        palette: {
            error: { main: COLOR_IMPORTANT },
            primary: {
                main: COLOR_BLUM,
            },
            secondary: {
                main: COLOR_STATUS_WAITING,
            },
            warning: {
                main: COLOR_IMPORTANT,
            },
        },
        props: {
            MuiButtonBase: {
                disableRipple: true,
            },
            MuiInput: {
                disableUnderline: true,
            },
        },
        typography: {
            fontFamily: ['euclid', 'sans-serif'].join(','),
        },
    }),
);
