import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LoadingSpinner from 'components/Loading';
import { EezyButton } from 'components/Buttons';
import {
    COLOR_BLUM,
    COLOR_LILA,
    INVOICE_WIDTH,
    SCREEN_M
} from 'styles/variables';
import { FillType, IInvoice } from '../../../../shared/src/types/invoice';
import Bar from '../nav/Bar';
import { clearForwardHistory, waitForNavigation } from '../../utils/navigation';

const Content = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    width: 100%;

    h2 {
        color: ${COLOR_LILA};
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.19px;
        text-transform: uppercase;
    }
    p {
        font-size: 15px;
    }

    button {
        margin-left: 10px;
    }

    @media (min-width: ${SCREEN_M}px) {
        margin-left: calc(${INVOICE_WIDTH}px + 45px);
        margin-right: 15px;
    }
`;

interface IInvoiceStatusProps {
    editable: boolean;
    fadeOff?: boolean;
    fillType?: FillType;
    invoice?: IInvoice;
    loading?: boolean;
}

const StatusBar = (props: IInvoiceStatusProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { editable, fillType, invoice, loading } = props;

    const goBack = async () => {
        navigate(-1);
        await waitForNavigation();
        navigate(`/invoices/view/${invoice?.id}`, { replace: true });
        await waitForNavigation();
        clearForwardHistory();
    };

    return (
        <Bar style={props.fadeOff ? { backgroundColor: COLOR_BLUM } : {}}>
            <Content>
                {fillType === 'free' ? (
                    <h2>{t('invoice.fill-type-free')}</h2>
                ) : fillType === 'guided' ? (
                    <h2>{t('invoice.fill-type-guided')}</h2>
                ) : editable ? (
                    <h2>{t('invoice.edit')}</h2>
                ) : (
                    <h2>{t('invoice.view')}</h2>
                )}

                <EezyButton color="purple" onClick={goBack} disabled={loading}>
                    {loading ? (
                        <LoadingSpinner size="1em" />
                    ) : (
                        t('menu.close')
                    )}
                </EezyButton>
            </Content>
        </Bar>
    );
};

export default StatusBar;
