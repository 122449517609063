import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IMessageInput } from '../../../../shared/src/types/message';
import { FormButton, FormInput } from '../../components/form';
import { isAccountTerminated, saveTermination } from '../../utils/profile/profileUtils';
import { CREATE_MESSAGE_THREAD, GET_MESSAGE_THREADS } from '../support/queries';
import { IS_ALLOWED_TO_REMOVE_ACCOUNT, REMOVE_ACCOUNT } from './queries';
import { FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'components/form/FormLabel';
import Dialog from 'components/ui/dialog';
import { P } from 'components/textElements';
import Select from 'components/ui/select';

type AccountRemovalReasonsProps = {
    selectedReason: string;
    setSelectedReason: (reason: string) => void;
    otherReason: string;
    setOtherReason: (reason: string) => void;
};

const AccountRemovalReasons = (props: AccountRemovalReasonsProps) => {
    const { t } = useTranslation();
    const terminationOptions = [0, 1, 2, 3, 4, 5, 6].map((i) => ({
        label: t(`profile:settings.terminate-account.termination-reasons.${i}`),
        value: i.toString(),
    }));
    return (
        <div className="mt-5">
            <FormLabel value={t('profile:settings.terminate-account.termination-reasons.label')}>
                {t('profile:settings.terminate-account.termination-reasons.label')}
            </FormLabel>
            <FormControl style={{ width: '100%' }} className="border-slate-300 border-b">
                <Select
                    options={terminationOptions}
                    triggerText={t('profile:settings.terminate-account.termination-reasons.select')}
                    onChange={(value) => {
                        props.setSelectedReason(value);
                    }}
                    value={props.selectedReason === '-1' ? '' : props.selectedReason}
                />
            </FormControl>
            {props.selectedReason === '6' && (
                <FormInput
                    label={t('profile:settings.terminate-account.termination-reasons.other-reasons')}
                    onChange={(val) => {
                        props.setOtherReason(val);
                    }}
                    name="other-reason"
                    placeholder={t('profile:settings.terminate-account.termination-reasons.type-here')}
                    required
                    value={props.otherReason}
                    style={{ marginTop: 20 }}
                />
            )}
        </div>
    );
};

const TerminateAccountDialog = ({ email }: { email: string }) => {
    const { t } = useTranslation();
    const [selectedReason, setSelectedReason] = useState('-1');
    const [otherReason, setOtherReason] = useState('');
    const [accountTerminated, setAccountTerminated] = useState(false);

    const [createMessageThread] = useMutation(CREATE_MESSAGE_THREAD, {
        onCompleted: () => {
            saveTermination(email);
            setAccountTerminated(true);
            toast(t('profile:settings.terminate-account.success'));
        },
        onError: () => toast.error(t('errors.general')),
        refetchQueries: () => [{ query: GET_MESSAGE_THREADS }],
    });

    useEffect(() => {
        setAccountTerminated(isAccountTerminated(email));
    }, [email]);

    const reason =
        otherReason === ''
            ? t(`profile:settings.terminate-account.termination-reasons.${selectedReason}`)
            : otherReason;

    const terminateAccount = (closeModal: () => void) => {
        const message: IMessageInput = {
            content: `${t('profile:settings.terminate-account.message-content')} ${t(
                'profile:settings.terminate-account.reason',
            )} ${reason}`,
            fileIds: [],
            subject: t('profile:settings.terminate-account.message-subject'),
        };
        createMessageThread({ variables: { message } });
        closeModal();
    };

    return (
        <Dialog
            triggerComponent={
                <FormButton
                    dark
                    disabled={accountTerminated}
                    label={t('profile:settings.terminate-account.label')}
                    name="remove-account"
                    style={{ marginTop: 30 }}
                    width={120}
                >
                    {t('profile:settings.terminate-account.button')}
                </FormButton>
            }
            handleSubmit={terminateAccount}
            title={t('profile:settings.terminate-account.title')}
            submitText={t('profile:settings.terminate-account.submit')}
            submitDisabled={selectedReason === '-1' || (selectedReason === '6' && otherReason === '')}
        >
            <P>{t('profile:settings.terminate-account.paragraph')}</P>
            <P>{t('profile:settings.terminate-account.paragraph2')}</P>
            <P>{t('profile:settings.terminate-account.paragraph3')}</P>
            <P>{t('profile:settings.terminate-account.paragraph4')}</P>
            <AccountRemovalReasons
                selectedReason={selectedReason}
                setSelectedReason={setSelectedReason}
                otherReason={otherReason}
                setOtherReason={setOtherReason}
            />
        </Dialog>
    );
};

const RemoveAccountDialog = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedReason, setSelectedReason] = useState('-1');
    const [otherReason, setOtherReason] = useState('');

    const [removeAccount] = useMutation(REMOVE_ACCOUNT, {
        onCompleted: () => {
            toast(t('profile:settings.remove-account.success'));
            setTimeout(() => {
                // Timeout for user to see the toast first
                window.location.href = '/u/login';
            }, 2000);
        },
        onError: () => toast.error(t('errors.general')),
    });

    const [createMessageThread] = useMutation(CREATE_MESSAGE_THREAD, {
        onError: () => toast.error(t('errors.general')),
        refetchQueries: () => [{ query: GET_MESSAGE_THREADS }],
    });

    const reason =
        otherReason === ''
            ? t(`profile:settings.terminate-account.termination-reasons.${selectedReason}`)
            : otherReason;

    const handleRemoveAccount = async (closeModal: () => void) => {
        setLoading(true);
        const message: IMessageInput = {
            content: `${t('profile:settings.remove-account.message-content')} ${reason}`,
            fileIds: [],
            subject: t('profile:settings.remove-account.message-subject'),
        };
        await createMessageThread({ variables: { message } });
        removeAccount();
        closeModal();
        setLoading(false);
    };

    return (
        <Dialog
            triggerComponent={
                <FormButton
                    dark
                    label={t('profile:settings.remove-account.label')}
                    name="remove-account"
                    style={{ marginTop: 30 }}
                    width={120}
                >
                    {t('profile:settings.remove-account.button')}
                </FormButton>
            }
            handleSubmit={handleRemoveAccount}
            title={t('profile:settings.remove-account.title')}
            submitText={t('profile:settings.remove-account.submit')}
            submitDisabled={
                selectedReason === '-1' || (selectedReason === '6' && otherReason === '') || loading
            }
            loading={loading}
        >
            <P>{t('profile:settings.remove-account.paragraph')}</P>
            <P>{t('profile:settings.remove-account.paragraph2')}</P>
            <P>{t('profile:settings.remove-account.paragraph3')}</P>
            <AccountRemovalReasons
                selectedReason={selectedReason}
                setSelectedReason={setSelectedReason}
                otherReason={otherReason}
                setOtherReason={setOtherReason}
            />
        </Dialog>
    );
};

export const ProfileSettingsRemoveAccount = (props: { email: string }) => {
    const { data } = useQuery(IS_ALLOWED_TO_REMOVE_ACCOUNT, {
        onError: () => null,
    });

    const isAllowedToRemove = data?.isAllowedToRemoveAccount;

    return isAllowedToRemove ? <RemoveAccountDialog /> : <TerminateAccountDialog email={props.email} />;
};
