export function clearForwardHistory() {
    // Check if the browser supports the History API
    if (history.pushState) {
        // Create a new state object with no data
        var newstate = {
            data: null,
            title: null,
            url: null
        };

        // Replace the current state with the new state, effectively clearing the forward history
        history.pushState(newstate, "", window.location.href);
    } else {
        // If the browser doesn't support the History API, try using a meta refresh to force a reload
        window.location.href = window.location.href;
    }
}

export function waitForNavigation() {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            requestAnimationFrame(() => {
                resolve();
            });
        }, 100);
    })
}