import { ALLOWED_CHART_LINE_NUMBER } from 'containers/statistics/statisticConstants';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import {
    DateFilter,
    dateRangeToString,
    getPeriod,
    getTimeLabel,
    isWithinSingleMonth,
    monthDateRange,
    yearDateRange,
} from 'utils/statistics/dateUtils';

export type SalesPageStatisticSuggestion = {
    dateRangeString: string;
    label: string;
    value: DateFilter;
};

type UseGenerateSuggestionsOptions = {
    dateFilter: DateFilter;
    chartDataDateStrings: string[];
    minDate: Date;
    selectSuggestion: (suggestion: SalesPageStatisticSuggestion) => void;
    closeModal: () => void;
};

type PeriodObject = {
    value: string | number;
    disabled: boolean;
    label: string;
    selected?: boolean;
    select: () => void;
};

export const useStatisticSuggestions = ({
    dateFilter,
    chartDataDateStrings,
    minDate,
    selectSuggestion,
    closeModal,
}: UseGenerateSuggestionsOptions) => {
    const currentYear = new Date().getFullYear();
    const showMonths = isWithinSingleMonth(dateFilter);

    const [year, setYear] = useState<number | null>(showMonths ? dateFilter.from.getFullYear() : null);

    useEffect(() => {
        setYear(showMonths ? dateFilter.from.getFullYear() : null);
    }, [dateFilter]);
    const minYear = minDate.getFullYear();
    const minMonth = year === minYear ? minDate?.getMonth() ?? 0 : 0;
    const maxMonth = year === currentYear ? new Date().getMonth() : 11;

    const dateRangesHaveYear = (year: number) => {
        if (year === currentYear) {
            const dateRangeString = dateRangeToString({
                from: moment().year(year).startOf('year').toDate(),
                to: new Date(),
            });
            return chartDataDateStrings.includes(dateRangeString);
        }
        return chartDataDateStrings.includes(dateRangeToString(yearDateRange(year)));
    };

    const dateRangesHaveMonth = (year: number, month: number) => {
        if (year === currentYear && month === new Date().getMonth()) {
            const dateRangeString = dateRangeToString({
                from: moment().year(year).month(month).startOf('month').toDate(),
                to: new Date(),
            });
            return chartDataDateStrings.includes(dateRangeString);
        }
        return chartDataDateStrings.includes(dateRangeToString(monthDateRange(year, month)));
    };

    const onSelectMonth = (month: number) => {
        if (!year) return;
        const from = moment().year(year).month(month).startOf('month').toDate();
        const to = moment.min(moment().year(year).month(month).endOf('month'), moment()).toDate();

        selectSuggestion({
            label: getTimeLabel({ from, to }),
            value: { from, to },
            dateRangeString: dateRangeToString({ from, to }),
        });
        closeModal();
    };

    const onSelectYear = (year: number) => {
        if (dateRangesHaveYear(year)) return;
        if (showMonths) return setYear(year);

        const from = moment().year(year).startOf('year').toDate();
        const to = moment.min(moment().year(year).endOf('year'), moment()).toDate();

        selectSuggestion({
            label: getTimeLabel({ from, to }),
            value: { from, to },
            dateRangeString: dateRangeToString({ from, to }),
        });
        closeModal();
    };

    const years: PeriodObject[] = Array.from(
        { length: currentYear - (minYear || 0) + 1 },
        (_, i) => minYear + i,
    )
        .reverse()
        .map((y) => ({
            value: y,
            disabled: dateRangesHaveYear(y),
            label: y.toString(),
            selected: y === year,
            select: () => onSelectYear(y),
        }));
    const months: PeriodObject[] = Array.from(
        { length: maxMonth - minMonth + 1 },
        (_, i) => minMonth + i,
    ).map((m) => ({
        value: m,
        disabled: dateRangesHaveMonth(year ?? currentYear, m),
        label: moment().month(m).format('MMMM'),
        select: () => onSelectMonth(m),
    }));

    const suggestions = useMemo(() => {
        if (dateFilter.from.getFullYear() !== dateFilter.to.getFullYear()) return [];

        const period = getPeriod(dateFilter);

        const NUMBER_OF_YEARS = ALLOWED_CHART_LINE_NUMBER - 1;
        const suggestions: SalesPageStatisticSuggestion[] = [];
        let count = 0;
        while (suggestions.length < NUMBER_OF_YEARS) {
            const year = new Date().getFullYear() - count - 1;
            count++;

            const from = moment(dateFilter.from).set({ year }).toDate();
            let to = moment(dateFilter.to).set({ year }).toDate();

            if (moment(from).isBefore(minDate)) break;

            if (period) to = moment(to).endOf(period).toDate();

            const dateRangeString = dateRangeToString({ from, to });
            if (chartDataDateStrings.some((s) => s === dateRangeString)) {
                continue;
            }

            suggestions.push({
                dateRangeString,
                value: { from, to },
                label: getTimeLabel({ from, to }),
            });
        }

        return suggestions;
    }, [dateFilter, chartDataDateStrings, minDate]);

    const disabled = chartDataDateStrings.length >= ALLOWED_CHART_LINE_NUMBER || suggestions.length === 0;

    return {
        dateRangeSuggestions: suggestions,
        disabled,
        years,
        months,
    };
};
