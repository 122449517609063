import moment from 'moment';
import { formatDate } from 'utils/time';

// Same as DateRange but with from and to as mandatory
export type DateFilter = {
    from: Date;
    to: Date;
};

const timeRangeObject = {
    this_month: () => ({
        from: moment().startOf('month').toDate(),
        to: new Date(),
    }),
    last_month: () => ({
        from: moment().subtract(1, 'months').startOf('month').toDate(),
        to: moment().subtract(1, 'months').endOf('month').toDate(),
    }),
    this_year: () => ({
        from: moment().startOf('year').toDate(),
        to: new Date(),
    }),
    last_year: () => ({
        from: moment().subtract(1, 'years').startOf('year').toDate(),
        to: moment().subtract(1, 'years').endOf('year').toDate(),
    }),
    this_quarter: () => ({
        from: moment().startOf('quarter').toDate(),
        to: new Date(),
    }),
    last_quarter: () => ({
        from: moment().subtract(1, 'quarters').startOf('quarter').toDate(),
        to: moment().subtract(1, 'quarters').endOf('quarter').toDate(),
    }),
};

export type TimeRange = keyof typeof timeRangeObject;

export const timeRanges = Object.keys(timeRangeObject).map((key) => ({
    id: key as TimeRange,
    dateRange: timeRangeObject[key as TimeRange],
}));

export const getTimeRange = (value: DateFilter) => {
    const timePeriod = timeRanges.find(({ dateRange }) => {
        const range = dateRange();
        return moment(value.from).isSame(range.from, 'date') && moment(value.to).isSame(range.to, 'date');
    });

    return timePeriod?.id;
};

const isThisMonth = (dateFilter: DateFilter) => getTimeRange(dateFilter) === 'this_month';
export const isFullMonth = (dateFilter: DateFilter) =>
    (moment(dateFilter.from).isSame(moment(dateFilter.from).startOf('month'), 'date') &&
        moment(dateFilter.to).isSame(moment(dateFilter.to).endOf('month'), 'date') &&
        moment(dateFilter.from).isSame(dateFilter.to, 'month')) ||
    isThisMonth(dateFilter);

const isThisYear = (dateFilter: DateFilter) => getTimeRange(dateFilter) === 'this_year';
export const isFullYear = (dateFilter: DateFilter) =>
    (moment(dateFilter.from).isSame(moment(dateFilter.from).startOf('year'), 'date') &&
        moment(dateFilter.to).isSame(moment(dateFilter.to).endOf('year'), 'date') &&
        moment(dateFilter.from).isSame(dateFilter.to, 'year')) ||
    isThisYear(dateFilter);

const isThisQuarter = (dateFilter: DateFilter) => getTimeRange(dateFilter) === 'this_quarter';
export const isFullQuarter = (dateFilter: DateFilter) =>
    (moment(dateFilter.from).isSame(moment(dateFilter.from).startOf('quarter'), 'date') &&
        moment(dateFilter.to).isSame(moment(dateFilter.to).endOf('quarter'), 'date') &&
        moment(dateFilter.from).isSame(dateFilter.to, 'quarter')) ||
    isThisQuarter(dateFilter);

export const getTimeLabel = (dateFilter: { from: Date; to: Date }) => {
    if (isFullYear(dateFilter)) return formatDate(dateFilter.from, 'yyyy');
    if (isFullQuarter(dateFilter)) {
        return `${formatDate(dateFilter.from, 'yyyy')} Q${moment(dateFilter.from).quarter()}`;
    }
    if (isFullMonth(dateFilter)) return formatDate(dateFilter.from, 'MMMM yyyy');

    return `${formatDate(dateFilter.from, 'DD.MM.yyyy')} - ${formatDate(dateFilter.to, 'DD.MM.yyyy')}`;
};

export const isWithinSingleMonth = (dateFilter: DateFilter) =>
    moment(dateFilter.from).isSame(dateFilter.to, 'month') &&
    moment(dateFilter.from).isSame(dateFilter.to, 'year');

export const getPeriod = (dateFilter: DateFilter) => {
    if (isFullYear(dateFilter)) return 'year';
    if (isFullQuarter(dateFilter)) return 'quarter';
    if (isFullMonth(dateFilter)) return 'month';

    return null;
};

export const yearDateRange = (year: number) => ({
    from: moment().year(year).startOf('year').toDate(),
    to: moment().year(year).endOf('year').toDate(),
});

export const monthDateRange = (year: number, month: number) => ({
    from: moment().year(year).month(month).startOf('month').toDate(),
    to: moment().year(year).month(month).endOf('month').toDate(),
});

export function dateRangeToString(dateRange: DateFilter) {
    return `${moment(dateRange.from).format('YYYY-MM-DD')} - ${moment(dateRange.to).format('YYYY-MM-DD')}`;
}
