import { Button } from 'components/common/Button';
import { Drawer, DrawerDivider } from 'components/ui/drawer';
import { useStatisticSuggestions } from 'hooks/statistics/useStatisticSuggestions';
import { useState } from 'react';
import { isWithinSingleMonth } from 'utils/statistics/dateUtils';
import { StatisticsBillingSuggestionsProps } from './StatisticsBillingSuggestions';
import { MenuButton } from 'components/common/MenuButton';
import { useTranslation } from 'react-i18next';

const StatisticBillingSuggestionsMobile = (props: StatisticsBillingSuggestionsProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const showMonthFilters = isWithinSingleMonth(props.dateFilter);

    const { dateRangeSuggestions, disabled, years, months } = useStatisticSuggestions({
        ...props,
        selectSuggestion: props.onSelect,
        closeModal: () => setOpen(false),
    });

    const triggerButton = (
        <Button
            className="flex md:hidden"
            variant="outline"
            startIcon={['far', 'shuffle']}
            disabled={disabled}
        >
            {t('statistic.compare')}
        </Button>
    );

    return (
        <Drawer
            open={open}
            onOpenChange={setOpen}
            trigger={triggerButton}
            title={showMonthFilters ? t('statistic.choose-month') : t('statistic.choose-year')}
        >
            <div className="mt-4">
                {showMonthFilters && (
                    <div className="mb-5">
                        <div className="tg-caption text-gray-500 mb-4">{t('statistic.suggestions')}</div>
                        <div className="flex gap-3 overflow-x-auto -mr-6 pb-2">
                            {dateRangeSuggestions.map((i) => (
                                <Button
                                    key={i.label}
                                    startIcon={['far', 'plus']}
                                    variant="outline"
                                    className="capitalize min-w-fit"
                                    onClick={() => {
                                        props.onSelect(i);
                                        setOpen(false);
                                    }}
                                >
                                    {i.label}
                                </Button>
                            ))}
                        </div>
                        <DrawerDivider />

                        <div className="flex gap-1 overflow-x-auto -mr-6 pb-2">
                            {years.map((y) => (
                                <MenuButton
                                    key={y.value}
                                    className="min-w-fit rounded-full"
                                    onClick={y.select}
                                    disabled={y.disabled}
                                    selected={y.selected}
                                >
                                    {y.label}
                                </MenuButton>
                            ))}
                        </div>
                    </div>
                )}

                <div className="max-h-[350px] px-2 overflow-y-auto" data-vaul-no-drag>
                    {(showMonthFilters ? months : years).map((period) => (
                        <Button
                            key={period.value}
                            variant="outline"
                            size="large"
                            className="w-full mb-2"
                            onClick={period.select}
                            disabled={period.disabled}
                        >
                            {period.label}
                        </Button>
                    ))}
                </div>
            </div>
        </Drawer>
    );
};

export default StatisticBillingSuggestionsMobile;
