import { useQuery } from '@apollo/client';
import { GET_INCOME } from 'containers/dashboard/queries';
import { useTranslation } from 'react-i18next';
import { currentMonth, currentYear, formatCents } from 'utils';
import { IIncomeSummary } from '../../../../shared/src/types/invoice';
import { Icon } from 'components/Icon';

const PendingPaymentInvoice = () => {
    const { t } = useTranslation();

    const { data } = useQuery(GET_INCOME, {
        variables: { year: currentYear(), month: currentMonth() },
    });
    const incomeData: IIncomeSummary = data?.income;

    return (
        <div className="py-6 flex gap-2.5 items-center" style={{ color: '#351F65' }}>
            <Icon icon={['far', 'clock']} />
            <div>{t('dashboard.invoices.pending-payment')}:</div>
            <b className="text-xl">{formatCents(incomeData?.incomeOpen ?? 0, true)} €</b>
        </div>
    );
};

export default PendingPaymentInvoice;
