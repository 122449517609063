import { gql } from '@apollo/client';

const ADD_INVOICE_ITEM = gql`
    mutation AddInvoiceItem(
        $description: String
        $endDate: String
        $invoiceId: Int!
        $itemType: InvoiceItemType!
        $price: Float
        $quantity: Float
        $quantityUnit: QuantityUnit
        $startDate: String
        $vat: Float
    ) {
        updateInvoiceItem(
            item: {
                description: $description
                endDate: $endDate
                invoiceId: $invoiceId
                itemType: $itemType
                price: $price
                quantity: $quantity
                quantityUnit: $quantityUnit
                startDate: $startDate
                vat: $vat
            }
        ) {
            id
            invoiceItems {
                description
                endDate
                id
                itemType
                price
                quantity
                quantityUnit
                startDate
                totalPrice
                totalPriceWithVat
                vat
            }
            total
            totalVats {
                percentage
                total
            }
            totalWithVat
        }
    }
`;

export default ADD_INVOICE_ITEM;
