import { CheckboxForSubscriptionTable } from './form/CheckboxForSubscriptionTable';
import { useTranslation } from 'react-i18next';
import { cn } from 'utils';
import { isBefore } from 'date-fns';

interface IProps {
    selectedSubscription: number;
    setSelectedSubscription: (subscriptionId: number) => void;
}

function EezyPayPricingTable(props: IProps) {
    const { t } = useTranslation();

    const { selectedSubscription, setSelectedSubscription } = props;

    const headers = [
        t('eezyPay:duration'),
        t('eezyPay:monthly-price'),
        t('eezyPay:package-price'),
        t('eezyPay:choose-package'),
    ];

    const discountActiveUntil = new Date('2024-04-04');

    const prices = [
        {
            months: 1,
            montlyPrice: 14.9,
            packagePrice: 14.9,
        },
        {
            months: 3,
            montlyPrice: 12.9,
            packagePrice: 38.7,
        },
        {
            months: 6,
            montlyPrice: 10.9,
            packagePrice: 65.4,
            discountMonthlyPrice: 8.33,
            discountPackagePrice: 50,
            discountActive: isBefore(new Date(), discountActiveUntil),
        },
        {
            months: 12,
            montlyPrice: 8.9,
            packagePrice: 106.8,
        },
    ];

    const classNameFromColIndex = (index: number) => {
        switch (index) {
            case 0:
                return 'bg-blum-100';
            case 1:
                return 'bg-blum-200';
            case 2:
                return 'bg-blum-300';
            case 3:
                return 'bg-blum-400';
        }
    };

    return (
        <div className="w-full overflow-x-auto pt-4">
            <div className="grid grid-flow-col grid-rows-4">
                {headers.map((header, index) => (
                    <div
                        key={index}
                        className={cn(
                            'flex justify-start items-center font-bold bg-lila-lightest h-20 p-2 border-black border-r',
                            index !== headers.length - 1 && 'border-b ',
                        )}
                    >
                        {header}
                    </div>
                ))}

                {prices.map((price, index) => (
                    <>
                        <div
                            key={index}
                            className={cn(
                                'flex justify-center items-center text-black font-bold p-2 border-black border-b relative',
                                classNameFromColIndex(index),
                                index !== prices.length - 1 && 'border-r',
                            )}
                        >
                            {price.months} {t('eezyPay:months')}
                            {price.discountActive && (
                                <div className="text-xs absolute bg-blum-900 text-white -top-3 p-2 mx-1 rounded-lg">
                                    {t('eezyPay:discount')}
                                </div>
                            )}
                        </div>
                        <div
                            className={cn(
                                'flex justify-center items-center text-black font-medium p-2 border-black border-b flex-col',
                                classNameFromColIndex(index),
                                index !== prices.length - 1 && 'border-r',
                            )}
                        >
                            <div className={cn(price.discountActive && 'line-through')}>
                                {price.montlyPrice.toFixed(2)} €
                            </div>

                            {price.discountActive && (
                                <div className="font-semibold">{price.discountMonthlyPrice.toFixed(2)} €</div>
                            )}
                        </div>
                        <div
                            className={cn(
                                'flex justify-center items-center text-black font-medium p-2 border-black border-b flex-col',
                                classNameFromColIndex(index),
                                index !== prices.length - 1 && 'border-r',
                            )}
                        >
                            <div className={cn(price.discountActive && 'line-through')}>
                                {price.packagePrice.toFixed(2)} €
                            </div>

                            {price.discountActive && (
                                <div className="font-semibold">{price.discountPackagePrice.toFixed(2)} €</div>
                            )}
                        </div>
                        <div
                            className={cn(
                                'flex justify-center items-center text-black font-medium p-2 border-black',
                                index !== prices.length - 1 && 'border-r',
                            )}
                        >
                            <CheckboxForSubscriptionTable
                                checked={selectedSubscription === index + 1}
                                color="black"
                                id={`${price.months}m`}
                                onChange={() => {
                                    setSelectedSubscription(index + 1);
                                }}
                            />
                        </div>
                    </>
                ))}
            </div>
        </div>
    );
}

export default EezyPayPricingTable;
