import { gql } from '@apollo/client';

const CREATE_SALES_PAGE = gql`
    mutation createSalesPage(
        $occupationId: Int!
        $publicPath: String!
        $serviceDescription: String
        $serviceName: String!
        $servicePrice: Int!
        $serviceVat: Float!
        $state: Int!
        $firstName: String!
        $lastName: String!
        $email: String!
        $phone: String
        $language: String!
        $userAccountId: Int!
    ) {
        insert_sales_pages(
            objects: [
                {
                    occupationId: $occupationId
                    publicPath: $publicPath
                    serviceDescription: $serviceDescription
                    serviceName: $serviceName
                    servicePrice: $servicePrice
                    serviceVat: $serviceVat
                    state: $state
                    userAccountId: $userAccountId
                    salesPagesUsers: {
                        data: {
                            firstName: $firstName
                            lastName: $lastName
                            email: $email
                            phone: $phone
                            language: $language
                        }
                        on_conflict: {
                            constraint: users_pkey
                            update_columns: [
                                firstName
                                lastName
                                email
                                phone
                                language
                            ]
                        }
                    }
                }
            ]
            on_conflict: {
                constraint: sales_pages_public_uuid_key
                update_columns: [
                    serviceDescription
                    servicePrice
                ]
            }
        ) {
            returning {
                id
                occupationId
                publicPath
                serviceDescription
                serviceName
                servicePrice
                serviceVat
                userId
            }
        }
    }
`;

export default CREATE_SALES_PAGE;
