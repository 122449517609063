import { useTranslation } from 'react-i18next';
import { Icon } from 'components/Icon';
import { CampaignButton } from '../constants';
import { EezyButton } from 'components/Buttons';

interface IFullCampaignBanner {
    image: string;
    title: string;
    shortTitle?: string;
    description: string;
    shortDescription?: string;
    buttons: CampaignButton[];
    onClose?: () => void;
    onClickButton?: (item: CampaignButton) => void;
}

const FullCampaignBanner = (props: IFullCampaignBanner) => {
    const { t } = useTranslation();

    return (
        <div className="pb-2 bg-violet-100 lg:pb-0 lg:pt-12">
            <div className="lg:px-2 lg:max-w-lg mx-auto">
                <div className="flex flex-col-reverse lg:flex-row bg-white lg:min-h-305 lg:rounded-eezy relative">
                    <div className="hidden lg:block py-1 font-medium bg-green-100 px-3.5 border-6 border-white absolute top-0 left-10 -translate-y-1/2 rounded-full text-green-700">
                        {t('banner.release-features.badge')}
                    </div>
                    <div className="flex flex-col px-6 pb-8 lg:px-0 lg:pl-10 lg:pt-14 text-center lg:text-left lg:max-w-full-banner">
                        <div
                            dangerouslySetInnerHTML={{ __html: props.title }}
                            className="hidden lg:block mb-5 text-2xl lg:text-28 font-semibold lg:text-gray-800 no-underline text-inherit"
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: props.shortTitle || props.title }}
                            className="lg:hidden mb-5 text-2xl lg:text-28 font-semibold lg:text-gray-800 no-underline text-inherit"
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: props.description }}
                            className="text-sm lg:text-txt-description mb-7 lg:mb-11"
                        />
                        {/* <div
                            dangerouslySetInnerHTML={{ __html: props.shortDescription || props.description }}
                            className="lg:hidden text-sm lg:text-txt-description mb-7 lg:mb-11"
                        /> */}
                        <div className="flex flex-col lg:flex-row gap-5">
                            {props.buttons.map((i) => (
                                <EezyButton
                                    key={i.value}
                                    className="v2-btn"
                                    dark={i.type === 'primary'}
                                    color={i.type === 'primary' ? 'eezy-green' : 'purple'}
                                    onClick={() => props.onClickButton?.(i)}
                                >
                                    {i.label}
                                </EezyButton>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-end justify-center ml-auto mb-7 lg:mb-0 lg:w-1/2 relative px-5 lg:px-0">
                        <img
                            src={props.image}
                            className="max-w-80p lg:max-w-full lg:absolute lg:inset-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2"
                        />
                    </div>
                    <div className="flex justify-end lg:justify-start py-3.5 px-5 lg:p-3">
                        <button className="inline-flex" onClick={props.onClose}>
                            <Icon icon={['far', 'xmark']} color="inherit" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
FullCampaignBanner.displayName = 'FullCampaignBanner';

export default FullCampaignBanner;
