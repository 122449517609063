import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ToastContentProps } from 'react-toastify/dist/types';
import styled from 'styled-components';
import { IOccupation } from '../../../../shared/src/types/invoice';
import { IUserData } from '../../../../shared/src/types/user';
import BackButtonWithTitle from '../../components/BackButtonWithTitle';
import { EezyButton } from 'components/Buttons';
import { Card } from 'components/cards';
import ErrorBox from '../../components/ErrorBox';
import { Flex } from 'components/Flex';
import { FormInput, FormNumber, FormSelect } from 'components/form';
import { Icon } from 'components/Icon';
import LoadingSpinner from '../../components/Loading';
import { SmallP } from 'components/textElements';
import { Toast } from 'components/Toast';
import config from 'config';
import { GtagCommands, GTMEvents } from 'constants/user';
import {
    COLOR_BLUM,
    COLOR_DARK_GRAY,
    COLOR_GREYJOY,
    COLOR_IMPORTANT,
    COLOR_STATUS_WAITING,
    COLOR_WHITE_WALKER,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    SCREEN_M,
    SCREEN_S,
    TOPBAR_HEIGHT,
} from 'styles/variables';
import { eursToCents, isMobile, trans } from 'utils';
import { sortObjectsByLabel } from 'utils/str';
import RelatedObjectMessage from '../dashboard/RelatedObjectMessage';
import { GET_OCCUPATIONS } from '../invoice/queries';
import { GET_USER_CONTACTS } from '../profile/queries';
import { CREATE_SALES_PAGE, GET_SALES_PAGES, GET_SALES_PAGES_BY_PATH } from './queries';
import SalesPageShareModal from './SalesPageShareModal';
import SuccessCard from './SuccessCard';
import { validateForbiddenTerms } from './validation/validation';
import getVatOptionsForPaymentPages from 'utils/invoice/getVatOptionsForPaymentPages';
import getVatDefaultForInvoice from 'utils/invoice/getVatDefaultForInvoice';
import { LabelValueType } from 'containers/statistics/statisticsPageTypes';

const VAT_TOAST_ID = 'vat-toast-id';

const forbiddenTermsFile =
    process.env.NODE_ENV === 'development' ? '/forbiddenTerms.txt' : '/u/forbiddenTerms.txt';

export const MainTitle = styled.h1`
    color: ${(props: { color?: string }) => (props.color ? props.color : COLOR_BLUM)};
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 30px;
`;

const StyledInfoText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${COLOR_GREYJOY};
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    max-width: 644px;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }
    margin: 0 auto;
    padding-top: 30px;
`;

const inputStyle = {
    flexGrow: 1,
    marginBottom: 20,
    marginTop: 0,
    width: '100%',
};

const SuccessCardWrapper = styled(Card)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
`;

const ToastMsg = styled.div`
    padding: 20px 30px 20px 20px;
    @media (max-width: ${SCREEN_S}px) {
        flex-direction: column;
    }
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const LoaderWrapper = styled.div`
    text-align: center;
    margin: 130px 0;
`;

const CustomInput = styled.input`
    &:disabled {
        color: ${COLOR_GREYJOY};
    }
`;

export interface ISalesPage {
    // eventDate?: Date;
    creationDate: Date;
    id: number;
    amountOfPayments?: number;
    occupationId: number;
    publicPath: string;
    serviceDescription?: string;
    serviceName: string;
    servicePrice: number;
    serviceVat: number;
    transactions_aggregate: {
        aggregate: {
            count: number;
        };
    };
    state: number;
}

interface ISalesPageCreateProps {
    user: IUserData;
    termsAccepted: boolean;
    userLoadingComplete: boolean;
    userState: number;
}

interface ISalesPageData {
    insert_sales_pages: {
        returning: ISalesPage[];
    };
}

const MIN_SERVICE_PRICE = 5;
const MAX_SERVICE_PRICE = 6000;

const renderErrorMessage = () => (
    <SmallP color={COLOR_IMPORTANT} style={{ marginTop: -15, marginBottom: 15 }}>
        {trans('eezyPay:required-information')}
    </SmallP>
);

const renderWarningMessage = (message: string) => (
    <SmallP color={COLOR_IMPORTANT} style={{ marginTop: -15, marginBottom: 15 }}>
        {message}
    </SmallP>
);

const VatToastMessage = ({ closeToast }: Partial<ToastContentProps>) => (
    <ToastMsg>
        <Flex>
            <Flex center>
                <div>
                    <Icon
                        color={COLOR_STATUS_WAITING}
                        icon={['fas', 'triangle-exclamation']}
                        style={{ margin: 'auto 14px auto 0' }}
                    />
                </div>
                <div>{trans('eezyPay:vat-toast-message')}</div>
            </Flex>
            <Icon
                onClick={closeToast}
                icon={['fal', 'times']}
                style={{ position: 'absolute', top: 10, right: 20 }}
            />
        </Flex>
    </ToastMsg>
);

const generatePublicPath = () => (Math.random() + 1).toString(36).substring(2);

const SalesPageCreate = (props: ISalesPageCreateProps) => {
    const { userLoadingComplete, userState, termsAccepted } = props;

    const { data: occupations, loading: occupationsLoading } = useQuery(GET_OCCUPATIONS);
    const { data: userData, loading: userDataLoading } = useQuery(GET_USER_CONTACTS, {
        fetchPolicy: 'network-only',
    });
    const { firstName, lastName } = props.user;
    const [serviceName, setServiceName] = useState<string>();
    const [serviceNameError, setServiceNameError] = useState<boolean>(false);
    const [serviceNameTermsError, setServiceNameTermsError] = useState<boolean>(false);
    const [servicePrice, setServicePrice] = useState<number>();
    const [servicePriceError, setServicePriceError] = useState<boolean>(false);
    const [servicePriceAmountError, setServicePriceAmountError] = useState<boolean>(false);
    const [serviceVat, setServiceVat] = useState<number>(getVatDefaultForInvoice());
    const [serviceVatError, setServiceVatError] = useState<boolean>(false);
    const [serviceDescription, setServiceDescription] = useState<string>();
    const [serviceDescriptionTermsError, setServiceDescriptionTermsError] = useState<boolean>(false);
    const [publicPath, setPublicPath] = useState<string>();
    const [occupationId, setOccupationId] = useState<number>();
    const [occupationError, setOccupationError] = useState<boolean>(false);
    const [pageCreated, setPageCreated] = useState<boolean>(false);
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [salesPage, setSalesPage] = useState<ISalesPage>();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [forbiddenTerms, setForbiddenTerms] = useState<string>('');

    const vatOptions: LabelValueType<string>[] = getVatOptionsForPaymentPages()
        .concat([serviceVat])
        .filter((v, i, a) => a.indexOf(v) == i)
        .map((i) => ({ label: i.toString(), value: i.toString() }));

    const occupationOptions =
        occupations?.allOccupations?.map((opt: IOccupation) => {
            return {
                label: trans(`occupations:${opt.id}`),
                value: opt.id.toString(),
            };
        }) || [];

    const mutationVariables = {
        onCompleted: (salesPagesData: ISalesPageData) => {
            toast(trans('general.saved'));
            setSalesPage(salesPagesData.insert_sales_pages.returning[0]);
            generateQrCode(salesPagesData.insert_sales_pages.returning[0].publicPath);
            setPageCreated(true);
            window.gtag && window.gtag(GtagCommands.event, GTMEvents.payment_page_created);
        },
        onError: () => {
            toast.error(trans('errors.general'));
        },
        refetchQueries: () => [
            {
                context: { clientName: 'eezyPayHasura' },
                query: GET_SALES_PAGES,
                variables: {
                    searchTerm: '%%',
                },
            },
        ],
    };
    const [createSalesPage, { loading: createSalesPageLoading }] = useMutation(CREATE_SALES_PAGE, {
        context: { clientName: 'eezyPayHasura' },
        ...mutationVariables,
    });

    const [getPageData] = useLazyQuery(GET_SALES_PAGES_BY_PATH, {
        context: { clientName: 'eezyPayHasura' },
        onCompleted: ({ sales_pages }) => {
            setServiceName(sales_pages[0].serviceName);
            setServicePrice(Number(sales_pages[0].servicePrice) / 100);
            setServiceVat(sales_pages[0].serviceVat);
            setServiceDescription(sales_pages[0].serviceDescription);
            setOccupationId(sales_pages[0].occupationId);
            setPublicPath(sales_pages[0].publicPath);
        },
    });

    useEffect(() => {
        const parsedUrl = new URL(window.location.href);

        if (parsedUrl.pathname.split('/')[3] === 'edit') {
            setEditMode(true);
            getPageData({
                variables: {
                    publicPath: parsedUrl.pathname.split('/')[4],
                },
            });
        } else {
            setPublicPath(generatePublicPath());
        }
    }, [getPageData]);

    useEffect(() => {
        if (serviceVat < 24) {
            toast(VatToastMessage, {
                autoClose: false,
                containerId: VAT_TOAST_ID,
                position: 'bottom-center',
                toastId: VAT_TOAST_ID,
            });
        }
    }, [serviceVat]);

    useEffect(() => {
        axios.get(forbiddenTermsFile).then((response) => {
            setForbiddenTerms(response.data);
        });
    }, []);

    const generateQrCode = (path: string) => {
        QRCode.toDataURL(`${config.eezyPayPublicPath}/${path}`, {
            margin: 1,
            width: 142,
        })
            .then((url: string) => {
                if (url) {
                    setQrCodeUrl(url);
                }
            })
            .catch((err: any) => {
                throw err;
            });
    };

    const getPaymentPageState = () => (serviceVat === 0 ? 2 : 1);

    const handleCreateSalesPage = () => {
        if (!serviceName) {
            setServiceNameError(true);
        } else {
            if (validateForbiddenTerms(serviceName, forbiddenTerms)) {
                setServiceNameTermsError(true);
            } else {
                setServiceNameTermsError(false);
            }
        }

        if (serviceDescription) {
            if (validateForbiddenTerms(serviceDescription, forbiddenTerms)) {
                setServiceDescriptionTermsError(true);
            } else {
                setServiceDescriptionTermsError(false);
            }
        } else {
            setServiceDescriptionTermsError(false);
        }

        if (!servicePrice) {
            setServicePriceError(true);
        }
        if (!serviceVat && serviceVat !== 0) {
            setServiceVatError(true);
        }
        if (!occupationId) {
            setOccupationError(true);
        }

        if (
            serviceName &&
            !validateForbiddenTerms(serviceName, forbiddenTerms) &&
            !validateForbiddenTerms(serviceDescription ? serviceDescription : '', forbiddenTerms) &&
            servicePrice &&
            !servicePriceAmountError &&
            (serviceVat || serviceVat === 0) &&
            occupationId
        ) {
            createSalesPage({
                variables: {
                    email: userData?.userData.email,
                    firstName,
                    language: props.user.language,
                    lastName,
                    occupationId,
                    phone: `+${userData?.userData.phoneCountryCode} ${userData?.userData.phone}`,
                    publicPath,
                    serviceDescription,
                    serviceName,
                    servicePrice: eursToCents(servicePrice),
                    serviceVat,
                    state: getPaymentPageState(),
                    userAccountId: props.user.accountId,
                },
            });
        }
    };

    const closeShareModal = () => {
        setShareModalOpen(false);
    };

    const handleCopyLinkClick = (path: string) => {
        navigator.clipboard.writeText(path);
        toast(trans('form.copied'));
    };

    const renderShareModal = (page: ISalesPage) => {
        if (!page) {
            return;
        }

        return (
            <SalesPageShareModal
                shareModalOpen={shareModalOpen}
                handleShareModalClose={closeShareModal}
                page={page}
                qrCodeUrl={qrCodeUrl}
                handleCopyLinkClick={() => {
                    handleCopyLinkClick(`${config.eezyPayPublicPath}/${page.publicPath}`);
                }}
            />
        );
    };

    if (pageCreated) {
        return (
            <Wrapper>
                <BackButtonWithTitle />
                <SuccessCardWrapper>
                    <SuccessCard
                        title={editMode ? trans('eezyPay:page-updated') : trans('eezyPay:page-created')}
                    />
                    <img src={qrCodeUrl} alt="" />
                    {getPaymentPageState() === 2 ? (
                        <ErrorBox style={{ marginTop: '30px' }}>
                            {trans('eezyPay:waiting-for-approval')}
                        </ErrorBox>
                    ) : (
                        <EezyButton
                            style={{ marginTop: '30px' }}
                            color="purple"
                            dark
                            onClick={() => {
                                setShareModalOpen(true);
                            }}
                        >
                            {trans('eezyPay:share')}
                        </EezyButton>
                    )}
                </SuccessCardWrapper>
                {salesPage && renderShareModal(salesPage)}
            </Wrapper>
        );
    }

    if (userState < 1 || userState > 1 || (!termsAccepted && userLoadingComplete)) {
        return (
            <ErrorBox style={{ marginTop: 27, padding: 27 }}>{trans('eezyPay:restricted-message')}</ErrorBox>
        );
    }

    const validatePrice = (price: number) => {
        if (price < MIN_SERVICE_PRICE || price > MAX_SERVICE_PRICE) {
            setServicePriceAmountError(true);
        } else {
            setServicePriceAmountError(false);
        }
    };

    return (
        <>
            <Wrapper>
                <BackButtonWithTitle />
                <Card>
                    <MainTitle>
                        {editMode ? trans('eezyPay:edit-sales-page') : trans('eezyPay:create-sales-page')}
                    </MainTitle>
                    {userDataLoading ? (
                        <LoaderWrapper>
                            <LoadingSpinner color={COLOR_BLUM} size="3em" />
                        </LoaderWrapper>
                    ) : (
                        <>
                            <StyledInfoText>*{trans('eezyPay:required-information')}</StyledInfoText>
                            <Flex column>
                                {/* Service name */}
                                <CustomInput
                                    as={FormInput}
                                    label={`${trans('eezyPay:service-name')} ${editMode ? '' : '*'}`}
                                    placeholder={trans('eezyPay:text-here')}
                                    name="serviceName"
                                    maxLength={255}
                                    onChange={(val: string) => {
                                        setServiceName(val);
                                        setServiceNameError(!val);
                                    }}
                                    style={inputStyle}
                                    value={serviceName}
                                    error={serviceNameError}
                                    disabled={editMode}
                                    tooltip={editMode ? trans('eezyPay:tooltip.field-disabled') : ''}
                                    tooltipColor={COLOR_WHITE_WALKER}
                                    tooltipBackground={editMode ? COLOR_DARK_GRAY : COLOR_BLUM}
                                    data-mf-replace="**REMOVED**"
                                />

                                {serviceNameError && renderErrorMessage()}

                                {/* Service price */}
                                <FormNumber
                                    label={`${trans('eezyPay:service-price')}*`}
                                    placeholder={trans('eezyPay:text-here')}
                                    name="servicePrice"
                                    endAdornment={trans('form.eurs', { eurs: '' }).trim()}
                                    onChange={(val: string | number) => {
                                        setServicePrice(Number(val));
                                        setServicePriceError(!val);
                                        validatePrice(Number(val));
                                    }}
                                    style={inputStyle}
                                    value={servicePrice}
                                    error={servicePriceError || servicePriceAmountError}
                                    required
                                />
                                {servicePriceError && renderErrorMessage()}
                                {servicePriceAmountError && (
                                    <SmallP
                                        color={COLOR_IMPORTANT}
                                        style={{
                                            marginBottom: 15,
                                            marginTop: -15,
                                        }}
                                    >
                                        {trans('eezyPay:service-price-error')}
                                    </SmallP>
                                )}

                                {/* VAT */}
                                <FormSelect
                                    id={serviceVat?.toString()}
                                    label={`${trans('eezyPay:vat')} ${editMode ? '' : '*'}`}
                                    name="vat"
                                    onChange={(val: string) => {
                                        setServiceVat(Number(val));
                                        setServiceVatError(!val);
                                    }}
                                    options={vatOptions}
                                    required
                                    selectStyle={inputStyle}
                                    value={serviceVat?.toString() || ''}
                                    showIcon
                                    noWrap
                                    error={serviceVatError}
                                    disabled={editMode}
                                    tooltip={
                                        editMode
                                            ? trans('eezyPay:tooltip.field-disabled')
                                            : trans('eezyPay:tooltip.vat')
                                    }
                                    tooltipColor={COLOR_WHITE_WALKER}
                                    tooltipBackground={editMode ? COLOR_DARK_GRAY : COLOR_BLUM}
                                />
                                {serviceVatError && renderErrorMessage()}
                                {serviceVat === 0 &&
                                    renderWarningMessage(trans('eezyPay:manually-approved-msg'))}

                                {/* Description */}
                                <FormInput
                                    label={trans('eezyPay:description')}
                                    placeholder={trans('eezyPay:text-here')}
                                    optional
                                    maxLength={255}
                                    name="serviceDescription"
                                    onChange={setServiceDescription}
                                    style={inputStyle}
                                    value={serviceDescription}
                                    data-mf-replace="**REMOVED**"
                                />

                                {/* Occupation */}
                                <FormSelect
                                    id={occupationId?.toString()}
                                    label={`${trans('eezyPay:occupation')} ${editMode ? '' : '*'}`}
                                    placeholder={trans('eezyPay:occupation-placeholder')}
                                    name="occupation"
                                    onChange={(val: string) => {
                                        setOccupationId(Number(val));
                                        setOccupationError(!val);
                                    }}
                                    options={sortObjectsByLabel(occupationOptions)}
                                    optionsLoading={occupationsLoading}
                                    required
                                    showIcon
                                    noWrap
                                    selectStyle={inputStyle}
                                    value={occupationId?.toString() || ''}
                                    error={occupationError}
                                    disabled={editMode}
                                    tooltip={
                                        editMode
                                            ? trans('eezyPay:tooltip.field-disabled')
                                            : trans('eezyPay:tooltip.occupation')
                                    }
                                    tooltipColor={COLOR_WHITE_WALKER}
                                    tooltipBackground={editMode ? COLOR_DARK_GRAY : COLOR_BLUM}
                                />
                                {occupationError && renderErrorMessage()}

                                <div style={{ marginTop: '20px' }}>
                                    {(serviceNameTermsError || serviceDescriptionTermsError) && (
                                        <>
                                            <SmallP
                                                color={COLOR_IMPORTANT}
                                                style={{
                                                    marginTop: -15,
                                                    marginBottom: 15,
                                                }}
                                            >
                                                {trans('eezyPay:text-validation-error-ln1')}
                                            </SmallP>
                                            <SmallP
                                                color={COLOR_IMPORTANT}
                                                style={{
                                                    marginTop: -15,
                                                    marginBottom: 15,
                                                }}
                                            >
                                                {trans('eezyPay:text-validation-error-ln2-part1')}{' '}
                                                <a
                                                    href="mailto:info.kevytyrittajat@eezy.fi"
                                                    style={{
                                                        color: COLOR_IMPORTANT,
                                                    }}
                                                >
                                                    {trans('eezyPay:text-validation-error-ln2-part2')}
                                                </a>{' '}
                                                {trans('eezyPay:text-validation-error-ln2-part3')}{' '}
                                                <a
                                                    href="tel:09472475630"
                                                    style={{
                                                        color: COLOR_IMPORTANT,
                                                    }}
                                                >
                                                    {trans('eezyPay:text-validation-error-ln2-part4')}
                                                </a>
                                            </SmallP>
                                        </>
                                    )}
                                </div>

                                <Flex justifyCenter style={{ margin: 10 }}>
                                    <EezyButton
                                        style={{ minWidth: 116 }}
                                        color="purple"
                                        dark
                                        onClick={() => {
                                            handleCreateSalesPage();
                                        }}
                                    >
                                        {createSalesPageLoading ? (
                                            <Flex
                                                center
                                                justifyCenter
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            >
                                                <LoadingSpinner size="1em" color={COLOR_WHITE_WALKER} />
                                            </Flex>
                                        ) : editMode ? (
                                            trans('eezyPay:update')
                                        ) : (
                                            trans('eezyPay:create')
                                        )}
                                    </EezyButton>
                                </Flex>

                                <Toast
                                    style={{
                                        left: isMobile() ? '0' : '50%',
                                        margin: isMobile() ? '0 2%' : '0 0 0 -321px',
                                        width: isMobile() ? '96%' : 642,
                                    }}
                                    containerId={VAT_TOAST_ID}
                                    isLarge
                                    hideIcon
                                    closeOnClick={false}
                                    position="bottom-center"
                                />
                            </Flex>
                        </>
                    )}
                </Card>
                <Flex style={{ marginTop: 10 }}>
                    <RelatedObjectMessage displayEezyPayBadge />
                </Flex>
            </Wrapper>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        termsAccepted: state.eezyPay.eezyPayTermsAccepted,
        user: state.user,
        userLoadingComplete: state.eezyPay.userLoadingComplete,
        userState: state.eezyPay.userState,
    };
};

export default connect(mapStateToProps, {})(SalesPageCreate);
