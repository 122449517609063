export const CHART_COMPARE_COLORS = ['#5B35A2', '#418B7E', '#0369A1', '#85e4eb'] as const;

export type ChartColor = (typeof CHART_COMPARE_COLORS)[number];

// this maps the above chart colors to their text tailwind classes, which are a bit darker then the colors
export const CHART_COMPARE_COLOR_TO_TAILWIND_TEXT = {
    '#5B35A2': 'text-violet-950',
    '#418B7E': 'text-green-700',
    '#0369A1': 'text-sky-800',
    '#85e4eb': 'text-blue-800',
} as Record<ChartColor, string>;

export const ALLOWED_CHART_LINE_NUMBER = 4;
