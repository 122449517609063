import React, { ReactNode } from 'react';
import BackButtonWithTitle from 'components/BackButtonWithTitle';
import { cn } from 'utils';

interface INestedPageWrapper {
    name: string;
    children: ReactNode;
    className?: string;
}

const NestedPageWrapper = (props: INestedPageWrapper) => {
    return (
        <div
            className={cn('bg-white px-6 md:px-14 py-10 flex gap-12 flex-col items-stretch', props.className)}
        >
            <div>
                <BackButtonWithTitle />
                <div className="mt-4 md:mt-5 tg-heading-2">{props.name}</div>
            </div>

            {props.children}
        </div>
    );
};

export default NestedPageWrapper;
