import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from 'components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from './Button';
import { cn } from 'utils';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from 'components/ui/drawer';

type ComboBoxProps = {
    options: { label: string; value: string }[];
    value: string;
    onChange: (value: string) => void;
    notFoundLabel: string;
    searchLabel: string;
    // Shown on on mobile
    title: string;
    selectLabel?: string;
    className?: string;
    // Limit the trigger button text width to a certain pixel value
    limitButtonTextWidthTo?: number;
};

export function Combobox({
    options,
    value,
    onChange,
    selectLabel,
    notFoundLabel,
    searchLabel,
    title,
    className,
    limitButtonTextWidthTo,
}: ComboBoxProps) {
    const [open, setOpen] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);

    const selectedOption = useMemo(() => options.find((option) => option.value === value), [options, value]);

    const trigger = (
        <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn('w-[250px] gap-3 px-4', className)}
            endIcon={['fas', 'chevron-down']}
        >
            <span
                className={cn(
                    limitButtonTextWidthTo &&
                        'text-ellipsis overflow-hidden whitespace-nowrap tg-body-medium '
                )}
                style={{ maxWidth: `${limitButtonTextWidthTo ?? 99999}px` }}
            >
                {value ? selectedOption?.label : selectLabel ?? 'Select'}
            </span>
        </Button>
    );

    const content = (
        <Command>
            <CommandInput placeholder={searchLabel} />
            <CommandList>
                <CommandEmpty>{notFoundLabel}</CommandEmpty>
                <CommandGroup>
                    {options.map((option) => (
                        <CommandItem
                            key={option.value}
                            value={option.value}
                            onSelect={(currentValue) => {
                                onChange(currentValue === value ? '' : currentValue);
                                setOpen(false);
                                setOpenMobile(false);
                            }}
                            className={cn(
                                'flex justify-between',
                                value === option.value && '!bg-violet-50 !text-violet-800 tg-body-bold'
                            )}
                        >
                            {option.label}
                            <FontAwesomeIcon
                                icon={['fas', 'check']}
                                className={cn('mr-2 opacity-70', value !== option.value && 'opacity-0')}
                            />
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </Command>
    );

    return (
        <div>
            <div className="hidden md:block">
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>{trigger}</PopoverTrigger>
                    <PopoverContent className="p-0">{content}</PopoverContent>
                </Popover>
            </div>
            <div className="block md:hidden">
                <Drawer open={openMobile} onOpenChange={setOpenMobile} trigger={trigger} title={title}>
                    {content}
                </Drawer>
            </div>
        </div>
    );
}
