import { gql } from '@apollo/client';

const GET_SALARIES = gql`
    query GetSalaries($page: Page, $searchTerm: String) {
        salaries(page: $page, searchTerm: $searchTerm) {
            count
            items {
                id
                paymentDate
                paidAmount
                status
                invoiceIds
                url
            }
            total
        }
    }
`;

export default GET_SALARIES;
