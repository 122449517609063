import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolTip from 'components/ui/tooltip';
import { cn } from 'utils';

type Icon = Parameters<typeof FontAwesomeIcon>[0]['icon'];

type IconButtonProps = {
    icon: Icon;
    size?: 'large' | 'medium' | 'small';
    color?: 'gray' | 'violet' | 'red';
    outline?: boolean;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    tooltip?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = ({
    icon,
    disabled,
    loading,
    size = 'medium',
    color = 'gray',
    outline,
    className,
    tooltip,
    ...props
}: IconButtonProps) => {
    const iconButton = (
        <button
            {...props}
            className={cn(
                'rounded-full transition-all flex items-center justify-center gap-2',
                disabled && 'opacity-40 cursor-default',
                {
                    large: 'size-11',
                    medium: 'size-9',
                    small: 'size-8',
                }[size],
                {
                    gray: 'text-gray-800 hover:bg-gray-100 active:bg-gray-200',
                    violet: 'text-violet-800 hover:bg-violet-50 active:bg-violet-100',
                    red: 'text-red-800 hover:bg-red-50 active:bg-red-100',
                }[color],
                outline && 'border border-gray-200 hover:border-gray-300 bg-white active:bg-gray-100',
                className,
            )}
        >
            {loading ? (
                <FontAwesomeIcon icon={['fas', 'spinner-third']} className="animate-spin" />
            ) : (
                <FontAwesomeIcon icon={icon} size={size === 'medium' ? 'lg' : 'sm'} />
            )}
        </button>
    );

    if (!tooltip) return iconButton;

    return (
        <ToolTip trigger={iconButton}>
            <p>{tooltip}</p>
        </ToolTip>
    );
};
