import moment from 'moment';
import { useState } from 'react';
import { CampaignButton } from '../constants';
import FullCampaignBanner from '../FullCampaignBanner';
import MiniCampaignBanner from '../MiniCampaignBanner';
import {
    getBannerStoredState,
    resetBannerStoredState,
    setBannerCloseTimes,
    toggleBannerVisible,
} from '../utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BannerStoreKeyName } from './constants';
import EezyDataBannerModal from '../EezyDataBannerModal';

const EezyDataBanner = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isOpenModal, setOpenModal] = useState(false);

    const [isTempClose, setTempClose] = useState(false);
    const from = t('banner.release-features.from-date');
    const to = t('banner.release-features.to-date');
    const image = '/e/imgs/banners/campaign_banner_lg.png';
    const title = t('banner.release-features.title');
    const description = t('banner.release-features.description');
    const shortDescription = t('banner.release-features.short-description');

    const buttons: CampaignButton[] = t('banner.release-features.buttons', { returnObjects: true });
    buttons.forEach((i) => {
        i.label = t(i.label);
    });

    if (isTempClose) {
        return null;
    }

    const inPeriod = moment().isBetween(from, to, 'day', '[]');

    if (!inPeriod) {
        return null;
    }

    const stored = getBannerStoredState(BannerStoreKeyName);

    if (!stored.visible) {
        return null;
    }

    if (stored.closeTimes >= 4) {
        if (moment(stored.closeDate).isAfter(from)) {
            return null;
        } else {
            resetBannerStoredState(BannerStoreKeyName);
        }
    }

    const onClose = () => {
        setTempClose(true);
        setBannerCloseTimes(BannerStoreKeyName, stored.closeTimes + 1);
        toggleBannerVisible(BannerStoreKeyName, false);
    };

    const showModalReadMore = () => {
        setOpenModal(true);
    };

    const onClickButton = (item: CampaignButton) => {
        if (item.value?.startsWith('/')) {
            navigate(item.value);
        } else if (item.value == 'open_modal') {
            showModalReadMore();
        } else {
            console.warn('[Banner]', item.value);
        }
    };

    const DisplayBanner = stored.closeTimes % 2 == 0 ? FullCampaignBanner : MiniCampaignBanner;

    return (
        <>
            <DisplayBanner
                image={image}
                title={title}
                description={description}
                shortDescription={shortDescription}
                buttons={buttons}
                onClose={onClose}
                onClickButton={onClickButton}
                onClickReadMore={showModalReadMore}
            />
            {isOpenModal && <EezyDataBannerModal onClose={() => setOpenModal(false)} />}
        </>
    );
};

export default EezyDataBanner;
