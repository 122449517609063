import React from 'react';
import { connect } from 'react-redux';
import { EezyButton } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { RowTitle } from '../../components/textElements';
import RestrictedWorks from '../docs/RestrictedWorks';
import { dispatch } from 'utils/store';
import { logout } from 'actions/auth';
import { useTranslation } from 'react-i18next';

interface IModalProps {
    accepted: boolean;
    handleConfirm: (document: string) => void;
    handleModalClose: () => void;
    isOpen: boolean;
    disableClose?: boolean;
}

const RestrictedWorkModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const confirm = () => {
        props.handleConfirm('RESTRICTED_WORK');
        props.handleModalClose();
    };

    const renderCloseButton = () => {
        if (props.disableClose) {
            return (
                <EezyButton
                    color="purple"
                    onClick={() => {
                        dispatch(logout());
                    }}
                >
                    {t('login.logout')}
                </EezyButton>
            );
        } else {
            return (
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {t('menu.close')}
                </EezyButton>
            );
        }
    };

    return (
        <Modal
            id="modal-yel"
            isOpen={props.isOpen}
            onClose={() => {
                if (!props.disableClose) {
                    props.handleModalClose();
                } else {
                    return null;
                }
            }}
            title={t('dashboard.messages.restricted-work.modal-title')}
            disableClose={props.disableClose}
        >
            <ModalContent>
                <RestrictedWorks />

                {(!props.accepted || props.disableClose) && (
                    <Flex spread style={{ marginTop: 10 }}>
                        <RowTitle color="black">
                            {t('dashboard.messages.restricted-work.modal-confirm')}
                        </RowTitle>
                        <EezyButton color="black" dark onClick={confirm}>
                            {t('form.confirm')}
                        </EezyButton>
                    </Flex>
                )}

                <ModalActions style={{ justifyContent: 'center' }}>{renderCloseButton()}</ModalActions>
            </ModalContent>
        </Modal>
    );
};

export default connect()(RestrictedWorkModal);
