import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { hideAlert, IAlertAction } from '../actions/app';
import AlertModal from '../components/modals/AlertModal';

/*
    AlertAction component provides a way to easily pop a confirmation modal
    asking "Are you sure you want to do this" by dispatching 
    an action `AlertAction(payload: IAlertAction)`.
    
    E.g.
    props.AlertAction({
        id: 'cost-delete',
        onAction: () =>
            handleDelete(r.id, r.type),
        texts: {
            paragraph:
                'costs.confirm-delete2',
            title: 'costs.confirm-delete'
        }
    })
*/

interface IAlertActionProps {
    closeModal: () => void;
    isOpen: boolean;
    modalData: IAlertAction;
}

const AlertAction = (props: IAlertActionProps) => {
    const handleClose = () => {
        props.closeModal();
    };

    if (!props.modalData) {
        return null;
    }

    return (
        <AlertModal
            id={props.modalData.id}
            isOpen={props.isOpen}
            handleClose={handleClose}
            texts={props.modalData.texts}
        />
    );
};

const mapStateToProps = (state: any) => {
    return {
        isOpen: !!state.app.alertModal,
        modalData: state.app.alertModal,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        closeModal: () => {
            dispatch(hideAlert());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertAction);
