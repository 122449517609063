import * as React from 'react';
import styled from 'styled-components';
import { MobileRowButton } from '../../components/Buttons';
import { P, PSmall, RowTitle } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYHOUND, COLOR_GREYS_ANATOMY } from '../../styles/variables';
import { useTranslation } from 'react-i18next';

interface IProps {
    lang?: 'fi' | 'en';
}

const Section = styled.div`
    margin: 30px 0;

    p {
        color: ${COLOR_GREYHOUND};
        margin-bottom: 5px;
        button {
            color: ${COLOR_BLUM};
            display: inline;
            margin-left: 5px;
        }
    }
`;

const InfoContainer = styled.div`
    background-color: ${COLOR_GREYS_ANATOMY};
    border-radius: 4px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
`;

const InfoText = styled(PSmall)`
    color: ${COLOR_GREYHOUND};
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }
`;

const TextItem = ({ text, infos }: { text: string; infos: string[] }) => {
    const [visible, setVisible] = React.useState(false);
    const { t } = useTranslation();

    return (
        <div>
            <PSmall>
                - {t(text)}
                <MobileRowButton onClick={() => setVisible(!visible)}>
                    {visible ? t('dashboard.messages.read-less') : t('dashboard.messages.read-more')}
                </MobileRowButton>
            </PSmall>
            {visible && (
                <InfoContainer>
                    {infos.map((item: string, i) => (
                        <InfoText key={`info-${i}`}>{t(item)}</InfoText>
                    ))}
                </InfoContainer>
            )}
        </div>
    );
};

const RestrictedWorks = (props: IProps) => {
    const { i18n, t } = useTranslation();

    React.useEffect(() => {
        if (props.lang) {
            i18n.changeLanguage(props.lang);
        }
    }, [props.lang, i18n]);

    return (
        <>
            <Section>
                <RowTitle color="black">
                    {t('dashboard.messages.restricted-work.non-invoiceable-work-title')}
                </RowTitle>
                <TextItem
                    text="dashboard.messages.restricted-work.1"
                    infos={['dashboard.messages.restricted-work.1-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.2"
                    infos={['dashboard.messages.restricted-work.2-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.3"
                    infos={['dashboard.messages.restricted-work.3-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.4"
                    infos={['dashboard.messages.restricted-work.4-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.5"
                    infos={['dashboard.messages.restricted-work.5-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.6"
                    infos={['dashboard.messages.restricted-work.6-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.7"
                    infos={['dashboard.messages.restricted-work.7-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.11"
                    infos={['dashboard.messages.restricted-work.11-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.12"
                    infos={['dashboard.messages.restricted-work.12-info']}
                />
            </Section>

            <Section>
                <P>
                    {t('dashboard.messages.restricted-work.contact')}
                    <a href="mailto:info.kevytyrittajat@eezy.fi">info.kevytyrittajat@eezy.fi</a>.
                </P>
            </Section>

            <Section>
                <RowTitle color="black">
                    {t('dashboard.messages.restricted-work.invoiceable-work-title')}
                </RowTitle>
                <P>{t('dashboard.messages.restricted-work.restriction-info')}</P>
                <TextItem
                    text="dashboard.messages.restricted-work.8"
                    infos={[
                        'dashboard.messages.restricted-work.8-info',
                        'dashboard.messages.restricted-work.8-info2',
                        'dashboard.messages.restricted-work.8-info3',
                        'dashboard.messages.restricted-work.8-info4',
                    ]}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.9"
                    infos={['dashboard.messages.restricted-work.9-info']}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.10"
                    infos={[
                        'dashboard.messages.restricted-work.10-info',
                        'dashboard.messages.restricted-work.10-info2',
                    ]}
                />
                <TextItem
                    text="dashboard.messages.restricted-work.13"
                    infos={[
                        'dashboard.messages.restricted-work.13-info',
                        'dashboard.messages.restricted-work.13-info2',
                    ]}
                />
            </Section>

            <Section>
                <P>
                    {t('dashboard.messages.restricted-work.end-note')}
                    <a href="mailto:info.kevytyrittajat@eezy.fi">info.kevytyrittajat@eezy.fi</a>.
                </P>
            </Section>
        </>
    );
};

export default RestrictedWorks;
