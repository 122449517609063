import React, { useEffect, useMemo, useRef, useState } from 'react';
import { formatCents } from 'utils/money';
import { IconButton } from 'components/common/IconButton';
import { cn } from 'utils';
import { CHART_COMPARE_COLOR_TO_TAILWIND_TEXT } from 'containers/statistics/statisticConstants';
import { SalesPageStatisticData } from 'containers/statistics/statisticsPageTypes';

type StatisticsBillingDetailProps = {
    data: SalesPageStatisticData;
    onRemove?: () => void;
    subtext?: string;
    isMainData?: boolean;
};

const StatisticsBillingDetail = ({
    data,
    subtext,
    onRemove: removePeriod,
    isMainData,
}: StatisticsBillingDetailProps) => {
    const actualSum = useMemo(
        () => data.items.reduce((r, x) => r + (isNaN(x.value) ? 0 : x.value), 0),
        [data.items]
    );
    const [sum, setSum] = useState(actualSum);

    const animationFrameRef = useRef<number>();

    // This makes the number update smoothly
    const updateVisibleSum = () => {
        setSum((sum) => {
            const diff = actualSum - sum;

            if (Math.round(diff) === 0) {
                return actualSum;
            }

            const step = diff / 3;

            return sum + step;
        });
        animationFrameRef.current = requestAnimationFrame(updateVisibleSum);
    };

    useEffect(() => {
        if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current);
        updateVisibleSum();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [actualSum]);

    return (
        <div
            className={cn(
                'flex items-center gap-4 relative',
                !isMainData &&
                    'border border-gray-100 rounded-lg bg-gray-50 px-5 py-4 min-w-[160px] flex-1 md:p-0 md:border-none md:bg-transparent md:flex-none md:min-w-0'
            )}
        >
            <div>
                <div
                    className={cn(
                        'tg-subtitle mb-2 text-gray-800',
                        isMainData && 'tg-heading-2',
                        !isMainData && CHART_COMPARE_COLOR_TO_TAILWIND_TEXT[data.color]
                    )}
                >
                    {formatCents(sum * 100, true)} <span className="tg-subtitle">€</span>
                </div>
                <div className="flex gap-3 items-center">
                    <div
                        className={cn(
                            'size-[10px] rounded-full',
                            isMainData && 'bg-violet-200 border border-violet-500'
                        )}
                        style={{ backgroundColor: isMainData ? '' : data.color }}
                    />
                    <div className="tg-caption text-gray-600">{subtext ?? data.name}</div>
                </div>
            </div>
            {!isMainData && (
                <IconButton
                    icon={['far', 'times']}
                    outline
                    onClick={removePeriod}
                    className="absolute md:sticky top-0 right-0 translate-x-[5px] -translate-y-[5px] md:transform-none"
                />
            )}
        </div>
    );
};

export default StatisticsBillingDetail;
