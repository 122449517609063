import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    const customMerge = extendTailwindMerge<'tg'>({
        extend: {
            classGroups: {
                tg: [
                    {
                        tg: [
                            // These are defined in globals.css
                            'display',
                            'heading-1',
                            'heading-2',
                            'heading-3',
                            'subtitle',
                            'body',
                            'body-medium',
                            'body-bold',
                            'caption',
                            'caption-medium',
                            'caption-bold',
                            'tiny',
                            'tiny-bold',
                        ],
                    },
                ],
            },
        },
    });
    return customMerge(clsx(inputs));
}
