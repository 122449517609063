import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { cn } from 'utils';
import { buttonVariants } from 'components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

export interface DropdownProps {
    /** The name attribute of the element. */
    name?: string;
    /** The caption displayed to replace the hidden select. */
    caption?: React.ReactNode;
    children?: React.SelectHTMLAttributes<HTMLSelectElement>['children'];
    className?: string;
    ['aria-label']?: string;
    style?: React.CSSProperties;
    /** The selected value. */
    value?: string | number;
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('p-3', className)}
            classNames={{
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
                month: 'space-y-4',
                caption: 'flex justify-center pt-1 relative items-center capitalize',
                caption_label: 'tg-body-bold',
                nav: 'space-x-1 flex items-center',
                nav_button:
                    'size-9 flex justify-center items-center rounded-full transition-all p-2 tg-body-bold border border-gray-200 text-gray-800 hover:text-black hover:border-gray-300 active:bg-gray-50 bg-white',
                nav_button_previous: 'absolute left-1',
                nav_button_next: 'absolute right-1',
                table: 'w-full border-collapse space-y-1',
                head_row: 'flex mb-2',
                head_cell: 'text-muted-foreground rounded-md w-11 md:w-9 tg-caption capitalize',
                row: 'flex w-full mt-1',
                cell: 'size-11 md:size-9 text-center p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-full [&:has([aria-selected].day-range-start)]:rounded-l-full [&:has([aria-selected])]:bg-violet-100 first:[&:has([aria-selected])]:rounded-l-full last:[&:has([aria-selected])]:rounded-r-full focus-within:relative focus-within:z-20 tg-body',
                day: cn(
                    buttonVariants({ variant: 'ghost' }),
                    'size-11 md:size-9 p-0 font-normal aria-selected:opacity-100 rounded-full'
                ),
                day_range_start: 'day-range-start',
                day_range_end: 'day-range-end',
                day_selected:
                    'bg-violet-800 text-primary-foreground hover:bg-violet-700 hover:text-primary-foreground',
                day_today: 'bg-accent text-accent-foreground',
                day_outside:
                    'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
                day_disabled: 'text-muted-foreground opacity-50',
                day_range_middle: 'aria-selected:bg-violet-100 aria-selected:text-violet-950',
                day_hidden: 'invisible',
                ...classNames,
            }}
            components={{
                IconLeft: () => <FontAwesomeIcon icon="chevron-left" />,
                IconRight: () => <FontAwesomeIcon icon="chevron-right" />,
            }}
            captionLayout="dropdown-buttons"
            {...props}
        />
    );
}
Calendar.displayName = 'Calendar';

export { Calendar };
