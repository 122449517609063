import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from 'utils';

type Icon = Parameters<typeof FontAwesomeIcon>[0]['icon'];
type ButtonColor = 'violet' | 'gray' | 'red';

type MenuButtonProps = {
    selected?: boolean;
    children: React.ReactNode;
    color?: ButtonColor;
    disabled?: boolean;
    loading?: boolean;
    startIcon?: Icon;
    endIcon?: Icon;
    className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const MenuButton = ({
    selected,
    children,
    startIcon,
    endIcon,
    disabled,
    loading,
    color = 'violet',
    className,
    ...props
}: MenuButtonProps) => {
    return (
        <button
            {...props}
            className={cn(
                'rounded-sm transition-all flex items-center justify-start gap-2 text-gray-800 hover:text-black active:bg-gray-200 py-2 px-3',
                disabled && 'opacity-40 cursor-default',
                selected
                    ? {
                          violet: 'bg-violet-100 text-violet-800 hover:text-violet-800',
                          gray: 'bg-gray-200',
                          red: 'bg-red-100',
                      }[color]
                    : {
                          violet: 'hover:bg-gray-100',
                          gray: 'hover:bg-gray-100',
                          red: 'hover:bg-red-50',
                      }[color],
                selected ? 'tg-body-bold' : 'tg-body-medium',
                className,
            )}
        >
            {startIcon && <FontAwesomeIcon icon={startIcon} size="lg" />}
            {children}
            {endIcon && <FontAwesomeIcon icon={endIcon} />}
            {loading && <FontAwesomeIcon icon={['fas', 'spinner-third']} className="animate-spin" />}
        </button>
    );
};
