import { DateRangePicker } from 'components/common/DateRangePicker';
import { IconButton } from 'components/common/IconButton';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { DateFilter, isFullMonth, isFullQuarter, isFullYear } from 'utils/statistics/dateUtils';
import { StatisticsClientPicker } from './StatisticsClientPicker';
import { useTranslation } from 'react-i18next';

type StatisticPageFiltersProps = {
    dateFilter: DateFilter;
    onChangeDateFilter: (dateFilter: DateFilter) => void;
    clients: string[];
    clientFilter: string | null;
    onChangeClientFilter: (client: string | null) => void;
    minDate: Date;
    showClientFilter?: boolean;
};

const StatisticPageFilters = ({
    dateFilter,
    onChangeDateFilter,
    clients,
    clientFilter,
    onChangeClientFilter,
    minDate,
    showClientFilter,
}: StatisticPageFiltersProps) => {
    const { t } = useTranslation();

    // Mobile date change needs to be confirmed, desktop doesn't
    const [unconfirmedDateFilter, setUnconfirmedDateFilter] = useState({
        to: moment().endOf('date').toDate(),
        from: moment().startOf('year').toDate(),
    });

    const period = useMemo(() => {
        if (
            dateFilter.from.getFullYear() == dateFilter.to.getFullYear() &&
            dateFilter.from.getMonth() == dateFilter.to.getMonth() &&
            isFullMonth(dateFilter)
        ) {
            return 'month';
        }

        if (dateFilter.from.getFullYear() == dateFilter.to.getFullYear() && isFullQuarter(dateFilter)) {
            return 'quarter';
        }

        return 'year';
    }, [dateFilter]);

    const isFullPeriod = useMemo(() => {
        switch (period) {
            case 'month':
                return isFullMonth(dateFilter);
            case 'quarter':
                return isFullQuarter(dateFilter);
            case 'year':
                return isFullYear(dateFilter);
        }
    }, [period]);

    const canGoToNext = moment().endOf(period).isAfter(moment(dateFilter.from).add(1, period));
    const canGoToPrevious = moment(minDate).isBefore(moment(dateFilter.to).add(-1, period));

    // Used for the next and previous buttons,
    // if options.next is true, it moves date range to next period, otherwise it moves it to the previous period
    // options.endOfPeriod makes it so that to is at the end of the period argument e.g. end of the month

    // endOfPeriod is used when we move from this month, which counts as "this month" but can be an incomplete month.
    // If we move backwards from there, it would just pick incomplete previous month, therefore we need to have the .endOf(period)
    // The option is only used when we are using currently a "full" time period, counting full months/years or this month/year up to this day.
    const moveFilterRange = (next: boolean) => {
        // Don't allow going to the future, or before the minDate
        if ((next && !canGoToNext) || (!next && !canGoToPrevious)) {
            return;
        }

        const fromMoment = moment(dateFilter.from).add(next ? 1 : -1, period);
        const toMoment = moment(dateFilter.to).add(next ? 1 : -1, period);

        if (isFullPeriod) {
            return onChangeFilterDateRange({
                from: fromMoment.startOf(period).toDate(),
                to: moment.min(toMoment.endOf(period), moment()).toDate(),
            });
        }

        onChangeFilterDateRange({
            from: fromMoment.toDate(),
            to: toMoment.toDate(),
        });
    };

    const onChangeFilterDateRange = (dateRange: DateRange, fromMobile?: boolean) => {
        if (!dateRange.from || !dateRange.to) return;
        const newDateFilter = {
            ...dateFilter,
            from: moment(dateRange.from).toDate(),
            to: moment(dateRange.to).toDate(),
        };

        // Mobile date change needs to be confirmed, desktop doesn't
        setUnconfirmedDateFilter(newDateFilter);
        if (!fromMobile) onChangeDateFilter(newDateFilter);
    };

    return (
        <div className="w-full md:w-auto">
            <div className="tg-caption-bold text-gray-800 mb-2">{t('statistic.dateRange')}</div>
            <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex gap-4 items-center justify-between flex-1 md:flex-initial md:justify-start">
                    <DateRangePicker
                        defaultTimeRange="this_year"
                        minDate={minDate}
                        maxDate={new Date()}
                        onDateRangeChange={onChangeFilterDateRange}
                        value={unconfirmedDateFilter}
                        onConfirmDateRange={() => onChangeDateFilter(unconfirmedDateFilter)}
                        onChangeCancel={() => setUnconfirmedDateFilter(dateFilter)}
                        className="w-full md:w-auto"
                    />
                    <div className="flex gap-0">
                        <IconButton
                            icon={['fas', 'chevron-left']}
                            onClick={() => moveFilterRange(false)}
                            disabled={!canGoToPrevious}
                        />
                        <IconButton
                            icon={['fas', 'chevron-right']}
                            onClick={() => moveFilterRange(true)}
                            disabled={!canGoToNext}
                        />
                    </div>
                </div>
                {showClientFilter && (
                    <div className="max-w-[350px]">
                        <StatisticsClientPicker
                            clients={clients}
                            value={clientFilter}
                            onChange={onChangeClientFilter}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default StatisticPageFilters;
