import { gql } from '@apollo/client';

const ADD_INVOICE = gql`
    mutation AddInvoice(
        $recipientId: String!
        $recipientType: ClientType!
        $contactPersonId: Int
        $fillType: FillType
    ) {
        updateInvoice(
            invoice: {
                contactPersonId: $contactPersonId
                recipient: { id: $recipientId, type: $recipientType }
                fillType: $fillType
            }
        ) {
            clientOrderNumber
            clientIdSiteNumber
            clientSiteNumber
            collectionRequested
            contactPersonId
            contactPersonName
            costInvoice {
                allowancesTotal
                id
                costInvoiceNumber
                receiptCosts {
                    amountsWithVats {
                        amount
                        id
                        vatPercent
                    }
                    createTime
                    description
                    id
                    purchaseDate
                    receiptFiles {
                        contentType
                        createTime
                        id
                        name
                        previewUrl
                        url
                    }
                    status
                    type
                }
                status
                travels {
                    allowancesTotal
                    dailyAllowancesFull {
                        quantity
                        salaryType
                        status
                        unitPrice
                    }
                    dailyAllowancesHalf {
                        quantity
                        status
                        unitPrice
                    }
                    endTime
                    id
                    receiptCosts {
                        amountsWithVats {
                            amount
                            id
                            vatPercent
                        }
                        createTime
                        description
                        id
                        purchaseDate
                        receiptFiles {
                            contentType
                            createTime
                            id
                            name
                            previewUrl
                            url
                        }
                        status
                        type
                    }
                    reimbursedKMs {
                        quantity
                        status
                        unitPrice
                    }
                    reimbursedMeals {
                        quantity
                        status
                        unitPrice
                    }
                    reimbursedTrailerKMs {
                        quantity
                        status
                        unitPrice
                    }
                    route
                    startTime
                    taxable
                    totalKMs
                    type
                }
                turnBackReason
            }
            creditRequested
            debtCollection
            description
            draftNumber
            dueDate
            eezyExpressStatus
            files {
                id
                name
                size
                url
            }
            fillType
            id
            interestPercentage
            invoiceItems {
                description
                endDate
                id
                itemType
                price
                quantity
                quantityUnit
                startDate
                totalPrice
                totalPriceWithVat
                vat
            }
            invoiceNumber
            invoiceDate
            isGroupInvoice
            messageToEezy
            notificationDays
            occupation
            paymentInformation {
                paidAmount
                referenceNumber
                reimbursedAmount
                totalWithVat
                unpaidAmount
            }
            paymentTerm
            recipient {
                active
                address {
                    country
                    street1
                    street2
                    town
                    zipCode
                }
                businessId
                contactPeople {
                    active
                    firstName
                    id
                    lastName
                }
                einvoiceOvt
                operator
                email
                firstName
                invoiceLanguage
                invoicingMethod
                id
                lastName
                name
                type
            }
            sendingFailed
            status
            template
            total
            totalVats {
                percentage
                total
            }
            totalWithVat
            turnBackReason
            url
        }
    }
`;

export default ADD_INVOICE;
