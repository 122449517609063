import { useLazyQuery, useMutation } from '@apollo/client';
import GET_STATISTIC_INVOICES from '../queries/getStatisticInvoices';
import { IInvoice } from '../../../../../shared/src/types/invoice';
import { format, isAfter } from 'date-fns';
import UPDATE_STATISTIC_INVOICE from '../queries/updateStatisticInvoice';

export type StatisticInvoice = {
    id: number;
    date: string;
    updatedDate: string;
    status: string;
    total: number;
    totalWithVat: number;
    paidAmount: number;
    partlyCredited: boolean;
    clientName?: string;
};

export const useStatisticInvoice = () => {
    const [getStatisticInvoices] = useLazyQuery<{ statisticInvoices: StatisticInvoice[] }>(
        GET_STATISTIC_INVOICES,
    );

    const [updateStatisticInvoice] = useMutation(UPDATE_STATISTIC_INVOICE);

    const getAndParseStatisticInvoices = async (from: Date, to: Date) => {
        const response = await getStatisticInvoices({
            variables: { from: format(from, 'yyyy-MM-dd'), to: format(to, 'yyyy-MM-dd') },
        });

        return response.data?.statisticInvoices;
    };

    const updateOneStatisticInvoice = async (invoice: StatisticInvoice) => {
        const { data } = await updateStatisticInvoice({ variables: { invoice } });

        const res = data.updateStatisticInvoice;

        return res;
    };

    // compare the two arrays, if a invoice has updatedAt later than the invoice in statisticInvoices it needs to be updated
    // return an array of updated statisticInvoices
    const updateStatisticInvoices = async (invoices: IInvoice[], statisticInvoices: StatisticInvoice[]) => {
        const statisticInvoicesToUpdate = statisticInvoices.filter((statisticInvoice) => {
            const invoice = invoices.find((invoice) => invoice.id === statisticInvoice.id);
            return (
                invoice &&
                invoice.updateDate &&
                isAfter(new Date(invoice.updateDate), new Date(statisticInvoice.updatedDate))
            );
        });

        // No await so that frontend gets the updated statistics and updating happens in the background
        Promise.all(
            statisticInvoicesToUpdate.map((statisticInvoice) =>
                updateStatisticInvoice({ variables: { invoice: statisticInvoice } }),
            ),
        );

        return statisticInvoicesToUpdate;
    };

    return {
        getStatisticInvoices: getAndParseStatisticInvoices,
        updateStatisticInvoices,
        updateOneStatisticInvoice,
    };
};
